import React, { Component } from 'react';
import { ToggleAngle } from './ToggleAngle';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { getLanguageForIsoCode } from './Languages';
import { ExtrasContent } from './ExtrasContent'

export class AssortmentRow extends Component {
    constructor(props) {
        super(props);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.stopPropagationClick = this.stopPropagationClick.bind(this);
        this.handleLanguageClick = this.handleLanguageClick.bind(this);
        this.state = {
            visibleExtraIndex: false,
        };
    }


    handleRowClick() {
        if (this.props.row.item.productLine == "Extras") {
            this.setState((prevState) => ({
                visibleExtraIndex: !prevState.visibleExtraIndex,
            }));
        }
        this.props.onClick(this.props.row.item.id, !this.props.row.node.isExpanded, null);
    }

    handleLanguageClick(id) {
        this.props.onClick(this.props.row.item.id, !this.props.row.node.isExpanded, id);
    }

    stopPropagationClick(event) {
        event.stopPropagation();
    }

    renderIcon(row) {
        if (row && row.node.type === "asset") {
            if (row.item && row.item.url && row.item.url.endsWith("zip")) {
                return <i className="asset-icon icon-file-archive"></i>;
            }
            //return <td><Thumbnail query={this.props.row.thumbUrl} /></td>;

            if (row.item.productLine == "Extras") {
                return <i className="asset-icon icon-file-text"></i>;
            }

            return <i className="asset-icon icon-pdf"></i>;
        }
        return;
        //i class="c-document-list-item-thumbnail svg-icon pr-8 icon-pdf icon-related-document" ></i
    }

    renderInformationClass() {
        if (this.props.row.item.informationClass.toLowerCase() === "internal") {
	        return ", Internal";
        }
        return null;
    }

    renderLanguage() {
        if (this.props.row.item.language) {
            return ", Language: " + getLanguageForIsoCode(this.props.row.item.language);      
        }
        return null; 
    }

    renderOtherLanguages(assets) {
       // const[dropdownOpen, setDropdownOpen] = useState(false);
        if (assets) {
            let sorted = assets.sort((a, b) => {
                const A = getLanguageForIsoCode(a.language);
                const B = getLanguageForIsoCode(b.language);
                if (A < B) {
                    return -1;
                }
                if (A > B) {
                    return 1;
                }
                return 0;
            });
        return (
            <UncontrolledDropdown onClick={this.stopPropagationClick} size="sm">
                <DropdownToggle caret className="language-dropdown">
            Download another language
            </DropdownToggle>
                <DropdownMenu>
                    {sorted.map(a => {
                    return (<DropdownItem key={a.assetId} onClick={() => this.handleLanguageClick(a.assetId)} > {getLanguageForIsoCode(a.language)} </DropdownItem> )})}
                </DropdownMenu>
            </UncontrolledDropdown>
            );
        }
        return null;
    }

    renderName(row) {
        return <p className="name">{row.item.name}</p>;
    }

    renderVersionAndFileSize(row) {
        if (row.item.productLine === "Extras") {
            const sizes = row.item.gpheUnitSizes.map((size) => <b className="unitSize">{size} </b>);
            return <p className="details">{sizes}</p>
        } else {
            return <p className="details ">Version {row.item.version}, {row.item.fileSize}</p>;
        }
    }

    renderTypeAndInformationClass(row) {
        if (row.item.productLine === "Extras") {
            return <p className="details">{row.item.parentName} {this.renderLanguage()} {this.renderInformationClass()}</p>;
        } else {
            return <p className="details">{row.item.type}{this.renderLanguage()} {this.renderInformationClass()}</p>;
        }
    }

    renderTableRowData() {
	    const row = this.props.row;
        if (row && row.node.type === "asset") {
            const classes = this.props.searchFilter ? 'td-asset td-asset-search' : 'td-asset';
            return <td className={classes}> <span className="svg-icon">{this.renderIcon(row)}</span>   <span className="asset-info"> {this.renderName(row)}{this.renderVersionAndFileSize(row)}{this.renderTypeAndInformationClass(row)}</span> {this.renderOtherLanguages(row.item.otherLanguages)}</td>;
        } else {
            return <td className="td-model">
                <p>{row.item.lifeCycleStatus === "Obsolete" && <i className="legacy-icon icon-support-tools"></i>}
                    {row.item.name}</p>
            </td >;
        }
    }
    

    render() {
        const row = this.props.row;
        let levelClass = row.item.url ? "level-" + row.node.level + ' bg-al-row-asset' : "level-" + row.node.level + " bg-al-row";
        if (row.item.productLine == "Extras") { levelClass = "level-" + row.node.level + 1 + ' bg-al-row-extra' };
        const collapsed = "collapsed-tr";
        if (!row.node.isVisible) {
            levelClass += " " + collapsed;
        }
        levelClass += " " + row.node.type;

        const { visibleExtraIndex } = this.state;

        return (
            <>
                <tr key={row.item.id.toString()} className={levelClass} onClick={this.handleRowClick}>
                    <th scope="row"><ToggleAngle level={row.node.level} isExpanded={row.node.isExpanded} id={row.item.id} isVisible={row.node.showAngle} /></th>
                    {this.renderTableRowData()}
                    <td className="download"> <i className="icon-download"></i></td>
                </tr >
                {row.item.productLine == "Extras" && (
                    <ExtrasContent content={row.item} isVisible={visibleExtraIndex} levelClass={levelClass} />  
                )}
            </>
        );
    }
}