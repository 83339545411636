import React, { Component } from 'react';
import { Button } from 'reactstrap';
import './DocumentTypeButtons.css';

export class DocumentTypeButtons extends Component {
    constructor(props) {
        super(props);

        this.state = {
	        cSelected: []
        };
        
        this.onCheckboxBtnClick = this.onCheckboxBtnClick.bind(this);
    }
    componentDidMount() {
        this.setDefaultFilter();
    }

    setDefaultFilter() {
        const types = (element) => element === "Technical data sheet" || element === "Spare part list";

        if (this.props.documentTypes.some(types)) {
            var selectedTypes = [];

            if (this.props.documentTypes.includes("Technical data sheet")) {
                selectedTypes.push("Technical data sheet");
            }
            if (this.props.documentTypes.includes("Spare part list")) {
                selectedTypes.push("Spare part list");
            }
            if (this.props.documentTypes.includes("Extras")) {
                selectedTypes.push("Extras");
            }

            this.setState(
                { cSelected: selectedTypes });

            var filter = selectedTypes.map(s => {
                const f = {
                    id: s,
                    label: s,
                    type: "Document"
                };
                return f;
            });

            this.props.onFilterChange(filter);
        }   
    }


    onCheckboxBtnClick(selected) {

	    const index = this.state.cSelected.indexOf(selected);
        if (index < 0) {
	        this.state.cSelected.push(selected);
        } else {
	        this.state.cSelected.splice(index, 1);
        }
        this.setState({ cSelected: [...this.state.cSelected] });

        var filter = this.state.cSelected.map(s => {
	        const f = {
		        id: s,
		        label: s,
		        type: "Document"
	        };
	        return f;
        });

        this.props.onFilterChange(filter);
    }

    clear() {
        this.setDefaultFilter();
    }

    render() {
        return (
            <div>
                {this.props.documentTypes.map(type => {
                    return (
                        <Button key={type} outline className="documentTypeBtn" onClick={() => this.onCheckboxBtnClick(type)} active={this.state.cSelected.includes(type)}>
                            <span>{type ? type : 'Unknown'}
                            </span>

                            
                        </Button>
                    );
                })}
                
            </div>  
	    );
    }
}

