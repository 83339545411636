import { selectLanguage } from '../components/table/Languages';
const url = process.env.REACT_APP_API_BASE_URL;


export function getAssortment(liveData, token, assortmentRole) {


    let params = '?';
    
    if (liveData) {
        params += 'useLiveData=' + liveData + '&';
    }
    
    if (assortmentRole) {
        params += 'assortmentRole=' + assortmentRole;
    }
 
    return fetch(url + '/api/v1/assortment' + params,
                {
                    headers: {
                        'accept': 'text/json;',
                        'Authorization': 'Bearer ' + token
                    }
        })
        .then(response =>
            response.json().then(data => ({
                data: data,
                name: response.headers.get("assortmentname")
            })
        ))
        .then(assortment => {
            if (assortment.data.message) {
                return { ok: false, data: assortment.data.message };
            }
            var rows = flatten(assortment.data);
            return { ok: true, data: rows, name: assortment.name };
        })
        .catch(error => {
            return {
                ok: false, data: "Unhandled error:" + error.message };
        });
}

function getFileSize(fileSizeInKb) {
    if (isNaN(fileSizeInKb)) {
	    return "0 MB";
    }

	return (fileSizeInKb / 1024 / 1000).toFixed(2) + " MB";
}

function addAssetRows(array, rows, type, parentId, assetProductLine) {
    array.forEach(function (a) {
        let name = a.name;
        let x;
        let otherLang = null;
        if (a.length === 1) {
            x = a[0];
        } else {
            x = selectLanguage(a);
            otherLang = a.filter(i => i.id !== x.id);
        }
        if (type === "asset") {
            name = x.displayName;
        }

        let item = null;

        if (assetProductLine == "Extras") {
            item = {
                id: x.id,
                assetId: x.assetId,
                name: x.blockID + " - " + name + " - " + x.moduleVariantId,
                description: x.description,
                parent: parentId,
                parentName: rows.find(elem => elem.item.id === parentId).item.name,
                extraType: x.extraType,
                moduleVariant: x.moduleVariantId,
                gpheUnitSizes: x.gpheUnitSizes,
                costAppliedFor: x.costAppliedFor,
                input: x.input,
                deliverables: x.deliverables,
                limitations: x.limitations,
                dependencies: x.dependencies,
                productLine: assetProductLine,
                type: assetProductLine,
                informationClass: x.informationClass,
                availableFrom: x.supplierScopes.includedIn,
                onRequest: x.supplierScopes.onRequest,
                language: x.language,
                fileExtension: '',
                assets: x.assets,
                links: x.links
            };
        }
        else {
            item = {
                id: x.id,
                assetId: x.assetId,
                name: name,
                type: x.type ? x.type : x.documentTaxonomy.name,
                parent: parentId,
                url: x.url,
                thumbUrl: x.thumbUrl,
                fileExtension: x.fileExtension,
                productLine: assetProductLine,
                fileSize: getFileSize(x.fileSize),
                informationClass: x.informationClass,
                version: x.version,
                language: x.language,
                otherLanguages: otherLang
            };
        }

        const node = {
            level: x.level,
            isExpanded: x.level < 1,
            isVisible: x.level < 2,
            type: type,
            isFiltered: true
        }
        const newRow = { item: item, node: node };
        rows.push(newRow);
    });
}

function flatten(data) {
    var rows = [];
    data.forEach(function (x) {

        const item = {
            id: x.id,
            name: x.name,
            type: x.type,
            lifeCycleStatus: x.lifeCycleStatus,
            assets: x.assets, 
            parent: x.parentId,
            typedParents: x.typedParents,
            parents: x.parents,
            children: x.children
        };
        const node = {
            level: x.level,
            isExpanded: x.level < 1,
            isVisible: x.level < 2,
            isFiltered: true,
            type: "node",
            showAngle: true
        }
        const row = { item: item, node: node };
        rows.push(row);
        if (row.item.assets && row.item.assets.length > 0) {
            const assetProductLine = getProductLine(x.typedParents, x.name);
            addAssetRows(row.item.assets, rows, "asset", row.item.id, assetProductLine);
        }
    });
    return rows;
}

function getProductLine(typedParents, name) {
    for (const [, value] of Object.entries(typedParents)) {
        if (value.key === "ProductLine") {
            return value.value;
        }
    }
    return name;
}