import { selectLanguage } from './table/Languages';

export function filterRows(documentFilters, productFilters, freeTextFilter, rows) {


    rows = rows.filter(row => filterRowsByDocumentFilters(row, documentFilters));

    rows = sortRows(rows);

    if (containsItems(productFilters)) {
        resetFilteredNodes(rows, true);
        const filteredRows = rows.filter(row => productFilters.includes(row.item.name.toUpperCase()));
        rows = enableSelfAndRelatives(rows, filteredRows);
    }

    rows = filterRowsByFreeText(rows, freeTextFilter);

    rows = setAngles(rows);

    return rows;
}

export function sortRows(rows) {
    function naturalSort(a,b) {
        return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
    }

    let categories = rows.reduce((acc, row, index) => {
        if (row.node.type === 'node') {
            acc[row.item.id] = { assets: [], position: index, node: row };
        } else if (row.node.type === 'asset') {
            acc[row.item.parent].assets.push(row);
        }
        return acc;
    }, {});

    Object.keys(categories).forEach(parent => {
        categories[parent].assets.sort((a, b) => naturalSort(a.item.name, b.item.name));
    });

    let sortedNodes = Object.values(categories).sort((a, b) => a.node.item.id - b.node.item.id);

    let sortedRows = [];

    sortedNodes.forEach(category => {
        sortedRows.push(category.node);
        sortedRows.push(...category.assets);
    });

    return sortedRows;
}

function filterRowsByFreeText(rows, freeTextFilter) {

    if (freeTextFilter && freeTextFilter.length > 0) {
        rows = rows.filter(r => r.node.isFiltered === true && r.node.type === "asset" && r.item.name.includes(freeTextFilter));
        rows.forEach(function (row) {
            row.node.isVisible = true;
        });
        rows = uniqueRowsByAssetId(rows);
    }
    return rows;
}

function setAngles(rows) {
    //const assetParentIds = uniqueAssetParentIds(rows);
    rows.forEach(function (row) {
        if (row.node.level === 1) {
            return;
        }
        if (row.node.type !== "asset" ){//}&& (assetParentIds.includes(row.item.id) || assetParentIds.includes(row.item.parent))) {
            row.node.showAngle = true;
        } else {
            row.node.showAngle = false;
        }
    }, this);

    return rows;
}

function uniqueAssetParentIds(rows) {
    const assetRows = rows.filter(row => row.node.type === "asset");
    let uniqueParentIds = [];
    assetRows.forEach(function(row) {
        const existing = uniqueParentIds.find(unique => unique === row.item.parent);
        if (!existing) {
            uniqueParentIds.push(row.item.parent);
        }
    });
    return uniqueParentIds;
}

function uniqueRowsByAssetId(rows) {
    let uniqueRows = [];
    rows.forEach(function (row) {
        const existingRow = uniqueRows.find(uniqueRow => uniqueRow.item.assetId === row.item.assetId);
        if (!existingRow) {
            uniqueRows.push(row);
        }
    });
    return uniqueRows;
}

function filterRowsByDocumentFilters(row, documentFilters) {
    if (!documentFilters || documentFilters.length === 0) {
        return true;
    }
    if (row.node.type === "node") {
        return true;
    }
    if (documentFilters.includes(row.item.type.toUpperCase())) {
        return true;
    }
    return false;
}

function enableSelfAndRelatives(rows, filteredRows) {
    filteredRows.forEach(function (row) {
        let rowIndex = rows.findIndex(r => { return r.item.id === row.item.id });
        rows[rowIndex].node.isFiltered = true;
        rows[rowIndex].node.isVisible = true;
        rows[rowIndex].node.isExpanded = true;
        const assets = rows[rowIndex].item.assets;
        if (assets) {
            setAssetRowsToFiltered(assets, rows, true);
        }

        if (row.node.type === "asset") {
            rowIndex = rows.findIndex(r => { return r.item.id === row.item.parent });
        }
        if (rowIndex < 0) {
            return;
        }

        rows = setRowsToFiltered(rows[rowIndex].item.parents, rows, true);
        rows = setRowsToFiltered(rows[rowIndex].item.children, rows, false);
    }, this);
    return rows;
}

function setRowsToFiltered(array, rows, visibleAssets) {
    if (!array || array.length === 0) {
        return rows;
    }
    array.forEach(function (filteredRowId) {
        const rowIndex = rows.findIndex(r => { return r.item.id === filteredRowId });
        if (rowIndex === -1) {
            return;
        }
        rows[rowIndex].node.isFiltered = true;
        rows[rowIndex].node.isVisible = true;
        const assets = rows[rowIndex].item.assets;
        if (assets) {
            rows[rowIndex].node.isExpanded = visibleAssets;
            setAssetRowsToFiltered(assets, rows, visibleAssets);
        }
    }, this);

    return rows;
}

function setAssetRowsToFiltered(assets, rows, visible) {
    if (!assets || assets.length === 0) {
        return rows;
    }
    assets.forEach(function (asset) {
        const assetId = asset.length === 1
            ? asset[0].id
            : selectLanguage(asset).id;
        const rowIndex = rows.findIndex(r => { return r.item.id === assetId });
        if (rowIndex === -1) {
            return;
        }
        rows[rowIndex].node.isFiltered = true;
        rows[rowIndex].node.isVisible = true && visible;
    }, this);

    return rows;
}

function resetFilteredNodes(rows) {
    rows.forEach(function (row) {
        row.node.isFiltered = false;
    }, this);
}

function containsItems(array) {
    if (array && array.length > 0) {
        return true;
    }
    return false;
}